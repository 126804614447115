<template>
<b-col>
  <b-row class="h-100 overflow-hidden">
    <b-col cols="12" lg="5" class="h-100 mb-5 p-0">
      <div class="agent-info-container pt-5 pb-3 bg-light">
        <ul class="px-5 m-0 list-unstyled">
          <li class="agent-info-list-item" data-aos="fade-left" data-aos-duration="500">
            <div class="icon-container">
              <div class="perspective-container">
                <div class="icon-bg"></div>
              </div>
              <unicon name="tablet" />
            </div>
            <span>
              <a href="#">
                {{ agentWebsiteDto.phoneNumber }}
              </a>
            </span>
          </li>
          <li class="agent-info-list-item" data-aos="fade-left" data-aos-duration="1000">
            <div class="icon-container">
              <div class="perspective-container">
                <div class="icon-bg"></div>
              </div>
              <unicon name="phone-alt" />
            </div>
            <span>     
              <a :href="'tel:' + agentWebsiteDto.email">
                {{ agentWebsiteDto.mobileNumber }}
              </a>
            </span>
          </li>
          <li class="agent-info-list-item" data-aos="fade-left" data-aos-duration="1500">
            <div class="icon-container">
              <div class="perspective-container">
                <div class="icon-bg"></div>
              </div>
              <unicon name="facebook-f" />
            </div>
            <span>
              <a :href="'https://' + agentWebsiteDto.email">
                {{ agentWebsiteDto.facebookLink }}
              </a>
            </span>
          </li>
          <li class="agent-info-list-item" data-aos="fade-left" data-aos-duration="2000">
            <div class="icon-container">
              <div class="perspective-container">
                <div class="icon-bg"></div>
              </div>
              <unicon name="envelope" />
            </div>
            <span>
              <a :href="'mailto:' + agentWebsiteDto.email">
                {{ agentWebsiteDto.email }}
              </a>
            </span>
          </li>
          <li class="agent-info-list-item" data-aos="fade-left" data-aos-duration="2000">
            <div class="icon-container">
              <div class="perspective-container">
                <div class="icon-bg"></div>
              </div>
              <unicon name="map-marker" />
            </div>
            <span>
              <a :href="'mailto:' + agentWebsiteDto.email">
                {{ agentWebsiteDto.address }}
              </a>
            </span>
          </li>
        </ul>
      </div>
      <div class="sub-title-bottom">
        <span>L</span>
        <span>A</span>
        <span>S</span>
        <span>I</span>
        <span>A</span>
        <span>F</span>
        <span>L</span>
        <span>A</span>
      </div>
    </b-col>
    <b-col cols="12" lg="7" class="p-0">
      <div class="agent-logo-container" data-aos="fade-right" data-aos-duration="5000">
        <div class="d-flex h-100">
          <div class="flex-grow-1 mb-2 d-flex flex-column">
            <div class="agent-content-title py-2">
              <span class="title-border flex-grow-1"></span>
              <h4>ALFAISAL</h4>
            </div>
            <div class="agent-content-image h-100">
              <div class="image mt-2 d-flex justify-content-center align-items-center">
                <img :src="agentWebsiteDto.imageDto.path ? agentWebsiteDto.imageDto.path.replace('~', $store.getters['app/domainHost']) : ''" width="260">
              </div>
              <div class="pl-5 ml-1">
                <div class="agent-content-title py-2 flex-grow-1">
                  <h4>SPINNING</h4>
                  <span class="title-border"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="left-border-style"></div>
        </div>
      </div>
    </b-col>
  </b-row>
</b-col>
</template>
<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['agentWebsiteDto'])
  },
  setup() {
      const { t } = useI18nUtils();
      return { t }
  }
}
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
  .agent-info-container {
    height: 479px;
    clip-path: polygon(80px 0, 100% 0, 100% 100%, 0 100%);
    display: flex;
    align-items: center;
    &:after,
    &:before {
      content: "";
      position: absolute;
      background: $secondaryPrimary;
      height: 3px;
      width: 65%;
    }
    &:after {
      left: 0;
      top: 0;
    }
    &:before {
      right: 0;
      bottom: 0;
    }
    .agent-info-list-item {
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      .icon-container {
        margin-right: 12px;
        position: relative;
        display: inline-block;
        .perspective-container {
          perspective: 100px;
          transform-style: preserve-3d;
          .icon-bg {
            background: $secondaryPrimary;
            width: 42px;
            height: 52px;
            transform: rotate3d(1, 0, 0, -31deg);
            border-radius: 4px;
          }
        }
        .unicon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -32%);
        }
      }
      > span {
        padding: 0 4px;
      }
    }
  }
  .sub-title-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    display: flex;
    width: 100%;
    padding: 0 16px;
    justify-content: space-between;
    color: $primary;
    span {
      padding: 10px;
    }
  }
  .agent-logo-container {
    height: 500px;
    width: calc(100% + 80px);
    position: relative;
    left: -80px;
    clip-path: polygon(0 0, 100% 0, calc(100% - 80px) 100%, 0 100%);
    .left-border-style {
      width: 40px;
      height: 100%;
      border-top: solid 96px $secondaryPrimary;
      border-bottom: solid 96px $secondaryPrimary;
      position: relative;
    }
    .agent-content-title {
      display: flex;
      h4 {
        letter-spacing: 24px;
        padding: 4px 24px 4px 0;
        color: $primary;
        margin: 0;
      }
      .title-border {
        flex-grow: 1;
        background: $primaryLight;
      }
    }
    .agent-content-image {
      border-top: solid 4px $primaryLight;
      border-bottom: solid 4px $primaryLight;
      display: flex;
      flex-direction: column;
      .image {
        background: $secondaryPrimary;
        flex-grow: 1;
      }
    }
  }
  [dir="ltr"] {
    .agent-info-container {
      clip-path: polygon(0 0, calc(100% - 80px) 0, 100% 100%, 0 100%);
      .agent-info-list-item {
        .icon-container {
          .perspective-container {
            .icon-bg {
              transform: rotate3d(1, 0, 0, 31deg);
            }
          }
        }
      }
    }
    .agent-logo-container {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 80px 100%);
      .agent-content-title {
        h4 {
          padding: 4px 0 4px 24px;
        }
      }
    }
  }
</style>
