<template>
  <div>
    <section class="agent pt-3">
      <div class="section-title-contaner mb-2">
        <h2 class="section-title right">{{ t("agent").ourexclusiveagent }}</h2>
      </div>
      <mainSectionContact />
    </section>
    <section class="contactus pb-5">
      <div class="contactus-container pb-5 px-lg-5">
        <b-container>
          <h2 class="pt-5 mb-2 pb-1 text-primary">
            {{ t("contactUs").title }}
          </h2>
          <ValidationObserver ref="observer">
            <b-form
              @submit.prevent="onSubmit"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <b-row>
                <b-col cols="12" lg="6">
                  <BTextInputWithValidation
                    rules="required"
                    :label="t('contactUs').subject"
                    :name="t('contactUs').subject"
                    v-model="setContactDto.title"
                    :placeholder="t('contactUs').placeholder"
                  />
                  <BTextInputWithValidation
                    rules="required"
                    :label="t('contactUs').name"
                    :name="t('contactUs').name"
                    v-model="setContactDto.name"
                    :placeholder="t('contactUs').placeholder"
                  />
                  <BTextInputWithValidation
                    rules="required|email"
                    :label="t('contactUs').email"
                    :name="t('contactUs').email"
                    v-model="setContactDto.email"
                    :placeholder="t('contactUs').placeholder"
                  />
                </b-col>
                <b-col cols="12" lg="6">
                  <validation-provider
                    :name="t('contactUs').messagebody"
                    rules="required"
                  >
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      :label="t('contactUs').message"
                    >
                      <b-form-textarea
                        rows="8"
                        :placeholder="t('contactUs').placeholder"
                        v-model="setContactDto.description"
                        :state="errors[0] ? false : valid ? true : null"
                      ></b-form-textarea>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-button
                  class="mx-auto px-3"
                  variant="primary"
                  type="submit"
                  >{{ t("contactUs").send }}</b-button
                >
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-container>
      </div>
    </section>
    <b-container>
      <div class="section-title-contaner mb-2">
        <h2 class="section-title right">{{ t("nav").contactus }}</h2>
      </div>
      <div class="px-lg-5">
        <b-row>
          <b-col cols="12" lg="6">
            <ul class="px-5 m-0 list-unstyled">
              <li
                class="agent-info-list-item"
                data-aos="fade-left"
                data-aos-duration="500"
              >
                <div class="icon-container">
                  <div class="perspective-container">
                    <div class="icon-bg"></div>
                  </div>
                  <unicon name="tablet" />
                </div>
                <span>
                  <a href="#">
                    {{ companyWebsiteDto.phoneNumber }}
                  </a>
                </span>
              </li>
              <li
                class="agent-info-list-item"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <div class="icon-container">
                  <div class="perspective-container">
                    <div class="icon-bg"></div>
                  </div>
                  <unicon name="phone-alt" />
                </div>
                <span>
                  <a :href="'tel:' + companyWebsiteDto.email">
                    {{ companyWebsiteDto.mobileNumber }}
                  </a>
                </span>
              </li>
              <li
                class="agent-info-list-item"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <div class="icon-container">
                  <div class="perspective-container">
                    <div class="icon-bg"></div>
                  </div>
                  <unicon name="facebook-f" />
                </div>
                <span>
                  <a :href="'https://' + companyWebsiteDto.email">
                    {{ companyWebsiteDto.facebookLink }}
                  </a>
                </span>
              </li>
              <li
                class="agent-info-list-item"
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <div class="icon-container">
                  <div class="perspective-container">
                    <div class="icon-bg"></div>
                  </div>
                  <unicon name="envelope" />
                </div>
                <span>
                  <a :href="'mailto:' + companyWebsiteDto.email">
                    {{ companyWebsiteDto.email }}
                  </a>
                </span>
              </li>
              <li
                class="agent-info-list-item"
                data-aos="fade-left"
                data-aos-duration="2500"
              >
                <div class="icon-container">
                  <div class="perspective-container">
                    <div class="icon-bg"></div>
                  </div>
                  <unicon name="map-marker" />
                </div>
                <span>
                  <a href="#">
                    {{ companyWebsiteDto.address }}
                  </a>
                </span>
              </li>
            </ul>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="map-container">
              <mapCompenets
                :latProp="companyWebsiteDto.latitude"
                :lngProp="companyWebsiteDto.longitude"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
  .map-container {
    position: relative;
    &:after,
    &:before {
      content: "";
      position: absolute;
      width: 3px;
      height: 50%;
      background: $primary;
      z-index: 1;
    }
    &:after {
      top: 0;
      right: -3px;
    }
    &:before {
      left: -3px;
      bottom: 0;
    }
  }
  .contactus {
    h2 {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 160px;
        height: 2px;
        background: $secondaryPrimary;
      }
    }
    .contactus-container {
      background: #f6f6f6;
    }
  }
  .agent-info-list-item {
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      .icon-container {
        margin-right: 12px;
        position: relative;
        display: inline-block;
        .perspective-container {
          perspective: 100px;
          transform-style: preserve-3d;
          .icon-bg {
            background: $secondaryPrimary;
            width: 42px;
            height: 52px;
            transform: rotate3d(1, 0, 0, -31deg);
            border-radius: 4px;
          }
        }
        .unicon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -32%);
        }
      }
      > span {
        padding: 0 4px;
      }
    }
</style>
<script>
  import mainSectionContact from "@/views/website/pages/contact/components/main-section-contact.vue";
  import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
  import { useUtils as useI18nUtils } from "@core/libs/i18n";
  import mapCompenets from "@/views/website/pages/contact/components/map.vue";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import { mapGetters, mapActions } from "vuex";
  import { required } from "@validations";
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      mainSectionContact,
      mapCompenets,
      BTextInputWithValidation,
    },
    data: () => ({
      required,
    }),
    metaInfo() {
      return {
        titleTemplate: "Al Faisal - " + this.$t("title.contactus"),
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: [
          {
            name: "description",
            content: "description",
          },
          {
            name: "keywords",
            content: "keywords, keywords, keywords, ...",
          },
        ],
      };
    },
    computed: {
      ...mapGetters(["companyWebsiteDto", "setContactDto"]),
    },
    setup() {
      const { t } = useI18nUtils();
      return { t };
    },
    mounted() {
        this.$store.dispatch("app/setLoadingWebsite", false);
    },
    methods: {
      ...mapActions(["setContact"]),
      onSubmit() {
        this.$refs.observer.validate().then((success) => {
          if (success) {
            this.setContact(this.setContactDto);
          }
        });
      },
    },
    beforeDestroy() {
        this.$store.dispatch("app/setLoadingWebsite", true);
    }
  };
</script>
